.headerContainer {
  display: none !important;
}

.loadingContainer {
  width: 100%;
  height: 100%;
}

.buttonsContainer {
  /* position: absolute; */
  /* width: 752px;
    right: 200px;
    z-index: 99; */
  /* width: 960px; */
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 18px;
  gap: 20px;
}

.filterButton {
  height: 34px;
  margin: 0 6px;
}

.addButton {
  height: 34px;
  margin-right: 80px;
}

.loadingContainer,
.errorContainer {
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.errorText {
  font-size: 32px;
  margin-top: 20px;
  margin-bottom: 12px;
}

.eventDisplay {
  width: 100%;
  display: flex;
  padding: 6px;
  border-radius: 4px;
}

.eventTime {
  font-size: 12px;
  font-weight: 300;
  margin-right: 6px;
  color: #fff;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 0;
}

.eventTitle {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 0;
}

.eventContainer {
  width: 300px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #434343;
  margin-bottom: -6px;
}

.titleIcon {
  color: #e5f7ff;
  font-size: 16px;
  margin-top: -9px;
  background-color: #2d3b51;
  padding: 6px;
  border-radius: 4px;
}

.eventContainerButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.eventButton {
  width: 48%;
  margin-bottom: 6px;
  font-size: 12px;
  text-align: center;
}

.divider {
  width: calc(100% + 34px);
  margin-left: -17px;
  margin-bottom: 16px;
  height: 1px;
  background-color: #efefef;
}

.groupLabel {
  font-size: 12px;
  font-weight: 300;
  opacity: 0.75;
  margin-bottom: 0px;
}

.groupValue {
  font-size: 12px;
  font-weight: 600;
  color: #434343;
  margin-top: 0px;
}

@media screen and (max-width: 1420px) {
  .buttonsContainer {
    position: relative;
  }
}

.dropEventsContainer {
  height: 320px;
  padding: 20px;
  position: relative;
}

.dropEventsListContainer {
  width: 320px;
}

.dropEventItem {
  padding: 10px 8px;
  border-radius: 4px;
  transition: 250ms ease;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropEventItem:hover {
  background-color: #eaeaea;
  transition: 250ms ease;
}

.dropEventItemName {
  font-weight: 500;
}

.dropEventItemStatus {
  font-size: 12px;
  font-weight: 300;
  padding-left: 8px;
}

.dropEventsHeader {
  width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* gap: 12px; */
}

.dropEventsHeaderTitle {
  font-size: 20px;
  font-weight: 500;
}

.dropEventsHeaderClose {
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;
  transition: 250ms ease;
}

.dropEventsHeaderClose:hover {
  background-color: #eaeaea;
  transition: 250ms ease;
}

.filterDropdown {
  width: 180px;
  margin-right: 0.75em !important;
}
